import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { IAutoJson, IAuto } from "../components/rechner/interfaces"
import { Header } from "../components/header"
import { Rechner } from "../components/rechner"

const VergleichPage = ({
  pageContext: { auto: selectedAuto = {} },
}: {
  pageContext?: { auto?: Partial<IAutoJson> }
}) => {
  useEffect(() => {
    console.log("selectedAuto", selectedAuto)
  }, [selectedAuto])

  return (
    <Layout>
      <SEO title="Home" />
      <Header title={["Wann lohnt sich ein ", selectedAuto.name + "?"]} />
      <Container fluid="lg">
        <Rechner auto={selectedAuto as IAuto} />
      </Container>
    </Layout>
  )
}

export default VergleichPage
